import React from "react";
import classNames from "classnames";
import { ArticleHero, Share, MiniNewsletter } from "@molecules";
import { Container, ButtonAlt, Icon } from "@atoms";
import ContentBuilder from "./ContentBuilder";
import RelatedArticles from "../molecules/RelatedArticles";

const ArticleBody = ({ articles, hero, contentBuilder, type, theme, slug }) => {
  const background = {
    white: "bg-white",
    offwhite: "bg-offwhite",
    lightTeal: "bg-teal-light",
    lighterTeal: "bg-teal-lighter",
    darkTeal: "bg-teal-dark",
    darkerTeal: "bg-black",
  }[theme];

  const color = {
    darkerTeal: "text-teal-dark/50",
    darkTeal: "text-teal/50",
    lightTeal: "text-teal-lighter/50",
    lighterTeal: "text-teal-lightest/50",
    offwhite: "text-white/50",
    white: "text-offwhite/50",
  }[theme];

  const isDark =
    theme === "darkTeal" || theme === "darkerTeal" || theme === "black";

  return (
    <section className="relative z-10 pt-10">
      {type === "whitepaper" && (
        <div
          className={classNames(
            "absolute inset-x-0 top-0 h-[15rem] overflow-hidden sm:h-[19rem] md:h-[23rem]",
            background
          )}
        >
          <Container variant="xs" className="absolute inset-0">
            <div className="relative h-full">
              <div className="pointer-events-none absolute -right-10 top-5 w-[12rem] -rotate-[11.5deg] md:-right-24 md:w-[16rem] lg:top-auto lg:-bottom-5 lg:-right-48">
                <Icon name="logoIcon" className={classNames("w-full", color)} />
              </div>
            </div>
          </Container>
        </div>
      )}
      <Container
        variant={type === "whitepaper" ? "xs" : null}
        className="relative z-10"
      >
        <ButtonAlt
          to="/insights"
          reverse
          color={isDark && type === "whitepaper" ? "monoTeal" : theme}
        >
          All articles
        </ButtonAlt>
        <div className="my-10 flex flex-wrap gap-20 md:gap-10">
          <div className="flex-1">
            <ArticleHero {...hero} theme={theme} />
            <ContentBuilder slug={slug} blocks={contentBuilder} />
            <Share className="mt-5" />
          </div>
          {type === "article" && (
            <div className="flex w-full flex-col gap-10 md:mt-3 md:w-1/3">
              <div className="hidden flex-col gap-5 md:flex">
                <Share />
                <MiniNewsletter
                  heading="Sign up for our newsletter."
                  descriptor="Get the latest news and updates on our work to transform the ECE system."
                />
              </div>
              <RelatedArticles articles={articles} className="mb-10 sm:mb-0" />
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

ArticleBody.defaultProps = {};

export default ArticleBody;
