import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  CbButton,
  CbCopy,
  CbCta,
  CbForm,
  CbImages,
  CbTestimonial,
  CbVideo,
} from "@molecules";

const ContentBuilder = ({ blocks, slug }) => {
  const spacing = "py-5";

  const formIdx = blocks.findIndex(b => b.type === "form");
  const [gate, setGate] = useState(-1);

  useEffect(() => {
    setGate(formIdx);
  }, []);

  useEffect(() => {
    const unlockedContent =
      JSON.parse(window.localStorage.getItem("unlockedContent")) || [];
    if (unlockedContent.includes(slug)) {
      setGate(999);
    }
    if (gate === 999) {
      window.localStorage.setItem(
        "unlockedContent",
        JSON.stringify([...unlockedContent, slug])
      );
    }
  }, [gate]);

  const ungatedBlocks =
    gate !== -1 && !blocks[formIdx].download
      ? [...blocks].slice(0, gate + 1)
      : blocks;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ungatedBlocks?.map((b, i) => {
        const { type, uid } = b;
        switch (type) {
          case "button":
            return (
              <div key={uid} className={classNames(spacing)}>
                <CbButton {...b} />
              </div>
            );
          case "copy":
            return (
              <div key={uid} className={classNames(spacing)}>
                <CbCopy {...b} />
              </div>
            );
          case "cta":
            return (
              <div key={uid} className={classNames(spacing)}>
                <CbCta {...b} />
              </div>
            );
          case "form":
            return (
              <div key={uid} className={classNames(spacing)}>
                {/* set gate to -1 upon success */}
                <CbForm
                  unlocked={gate === 999}
                  onSuccess={() => setGate(999)}
                  {...b}
                />
              </div>
            );
          case "images":
            return (
              <div key={uid} className={classNames(spacing)}>
                <CbImages {...b} />
              </div>
            );
          case "testimonial":
            return (
              <div key={uid} className={classNames(spacing)}>
                <CbTestimonial {...b} />
              </div>
            );
          case "video":
            return (
              <div key={uid} className={classNames(spacing)}>
                <CbVideo {...b} />
              </div>
            );
          default:
            return (
              <div
                key={uid}
                className="flex w-full items-center justify-center p-10"
              >
                {type}
              </div>
            );
        }
      })}
    </>
  );
};

ContentBuilder.defaultProps = {};

export default ContentBuilder;
